<template >
    <div>
        <div class="app-modal__box">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0">
                        {{
                            $t("message.edit_m", {
                                m: $t("message.costType"),
                            })
                        }}
                    </p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 pb-0" v-loading="loadingData">
                <div class="timeline-items__right rounded-sm w-100 p-4">
                    <el-form ref="form" :model="form" :rules="rules">
                        <el-row :gutter="20">
                            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                                <el-form-item
                                prop="name"
                                :label="$t('message.name')"
                                >
                                    <el-input
                                        :placeholder="$t('message.name')"
                                        v-model="form.name"
                                        size="medium"
                                    ></el-input>
                                </el-form-item>   
                            </el-col>
                            <!-- end-col -->
                            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                                <el-form-item
                                prop="money_amount"
                                :label="$t('message.money_amount')"
                                >
                                    <crm-money-input
                                        v-model="form.money_amount"
                                        :old="form.money_amount"
                                        :size="'medium'"
                                    >
                                    </crm-money-input>
                                </el-form-item>
                            </el-col>
                            <!-- end-col -->
                            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                                <el-form-item
                                prop="currency_id"
                                :label="$t('message.currency_id')"
                                >
                                    <select-currency
                                        :size="'medium'"
                                        ref="currencySelect"
                                        :placeholder="columns.currency_id.title"
                                        :id="form.currency_id"
                                        v-model="form.currency_id"
                                        >
                                    </select-currency>
                                </el-form-item>
                            </el-col>
                            <!-- end-col -->
                            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                                <el-form-item
                                prop="comment"
                                :label="$t('message.comment')"
                                >
                                    <el-input
                                        :placeholder="$t('message.comment')"
                                        v-model="form.comment"
                                        size="medium"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <!-- end-col -->
                        </el-row>
                    </el-form>
                </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import selectCurrency from "@/components/inventory/select-currency";

export default {
    mixins: [form, drawer, show],
    name: "costTypes",
    components: {
        selectCurrency
    },
    data() {
        return {};
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "costTypes/rules",
            model: "costTypes/model",
            columns: "costTypes/columns",
        }),
    },
    methods: {
        ...mapActions({
            update: "costTypes/update",
            show: "costTypes/show",
        }),
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (res.status == 200) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
};
</script>
